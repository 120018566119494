import React, { Fragment, useMemo, useState } from "react";
import Hero from "../components/common/hero";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import CtaSection from "../components/ctaSection";
import { graphql } from "gatsby";
import LocalizedLink from "../components/localizedLink";
import { sendEvent } from "../utils/googleMeasurementProtocol";
import reactStringReplace from "react-string-replace";
import CalendarIllu from "../../static/images/bookDemo/calendar.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Transition } from "@headlessui/react";
import { HiCheck, HiX } from "react-icons/hi";

const ContactSupport = ({ location, data: { page } }) => {
  const [isSent, setIsSent] = useState(false);
  const { lang } = useTranslations();

  const Schema = useMemo(
    () =>
      Yup.object().shape({
        fullName: Yup.string().required(),
        company: Yup.string().required(),
        email: Yup.string().email().required(),
        message: Yup.string().required(),
      }),
    []
  );

  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={page.seo.metaTitle}
        metas={[{ name: "description", content: page.seo.metaDescription }]}
      />
      <Hero title={page.header?.title} subtitle={page.header?.subtitle} />
      <div className="w-full bg-gray-50">
        <div className="relative max-w-3xl px-4 mx-auto">
          <div className="pt-12 pb-12">
            <Formik
              initialValues={{
                fullName: "",
                email: "",
                company: "",
                message: "",
              }}
              validationSchema={Schema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                window.dataLayer.push({
                  event: "contact_support",
                  user_data: {
                    address: {
                      first_name: values.fullName,
                    },
                    email_address: values.email,
                  },
                  company: values.company,
                  message: values.message.replace(/(\r\n|\n|\r)/gm, " "),
                });
                setSubmitting(false);
                setIsSent(true);
                resetForm();
              }}
            >
              {({ values, isSubmitting, setFieldValue, errors, touched }) => (
                <Form className="flex flex-col gap-4">
                  <h3 className="mb-1 text-xl font-medium text-gray-900 sm:text-3xl sm:tracking-tight lg:text-2xl">
                    {page.form?.title}
                  </h3>
                  {page.form?.strapi_fields?.map((field) => (
                    <div>
                      <div className="mt-1">
                        {field.type === "textarea" ? (
                          <textarea
                            name={field.fieldId}
                            id={field.fieldId}
                            className={`bg-gray-200 focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-md h-48 py-4 pl-5 rounded-md ${
                              errors[field.fieldId] && touched[field.fieldId]
                                ? "border-red-500"
                                : "border-none"
                            }`}
                            onChange={(e) =>
                              setFieldValue(field.fieldId, e.target.value)
                            }
                            value={values[field.fieldId]}
                            placeholder={field.placeholder}
                          />
                        ) : (
                          <input
                            type={field.type}
                            name={field.fieldId}
                            id={field.fieldId}
                            className={`bg-gray-200 focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-md h-12 py-7 pl-5 rounded-md ${
                              errors[field.fieldId] && touched[field.fieldId]
                                ? "border-red-500"
                                : "border-none"
                            }`}
                            onChange={(e) =>
                              setFieldValue(field.fieldId, e.target.value)
                            }
                            value={values[field.fieldId]}
                            placeholder={field.placeholder}
                          />
                        )}
                      </div>
                      {errors[field.fieldId] && touched[field.fieldId] && (
                        <p className="mt-1 text-sm text-red-600">
                          {field.errorMessage}
                        </p>
                      )}
                    </div>
                  ))}

                  <div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="ml-auto w-full mt-4 block rounded-md border border-transparent px-5 py-3 bg-cyan-600 text-base font-medium text-white shadow hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cyan-700 sm:px-10 disabled:opacity-50 "
                    >
                      {page.form.buttonValid}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Transition.Root show={isSent} as={Fragment}>
        <div as="div" className="relative z-10" onClose={setIsSent}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="absolute top-0 right-0 pt-4 pr-4 block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setIsSent(false)}
                    >
                      <span className="sr-only">Close</span>
                      <HiX className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <HiCheck
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <div
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {page.form?.successTitle}
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {page.form?.successDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Transition.Root>
    </>
  );
};

export const query = graphql`
  query StrapiContactSupportPage($locale: String) {
    page: strapiContactSupportPage(locale: { eq: $locale }) {
      header {
        subtitle
        title
      }
      seo {
        metaTitle
        metaDescription
      }
      form {
        title
        buttonValid
        strapi_fields {
          fieldId
          placeholder
          order
          label
          type
          errorMessage
        }
        successTitle
        successDescription
      }
    }
  }
`;

export default ContactSupport;
